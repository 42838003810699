import { useEffect, useState, MouseEvent } from 'react';
import { useRouter } from 'next/router';
import { destroyCookie, parseCookies } from 'nookies';
import Link from 'next/link';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  Login, Menu as MenuIcon, PersonSearchRounded, ImageSearchRounded, WorkOutline,
} from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { useApi, useCurrentUser, useCurrentTeamId } from 'lib/contexts/ApplicationState';
import {
  userHasSubscription, userIsProOrAdmin, userIsAdmin, userIsTeamMember,
} from 'lib/helpers';
import { isGondolaApp } from 'lib/utils/mobileAppUtils';
import { trackClick } from 'lib/gtag';
import { trackClickProfileEvent } from 'lib/hooks/useEventTracking';
import { User } from 'lib/types/users';
import { Team } from 'lib/types/teams';
import { GlobalSearch } from 'components/GlobalSearch/GlobalSearch';
import { ProIcon } from 'components/ProBadge/ProIcon';
import { AwaitUserAuth } from 'components/AwaitUserAuth';
import { TeamsBadge } from 'components/TeamsBadge';
import { DesktopLogo } from './DesktopLogo';
import { HideOnScroll } from './HideOnScroll';
import { Search } from './Search';

export function PrimaryAppBar() {
  const API = useApi();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newCount, setNewCount] = useState(0);
  const [currentTeam, setCurrentTeam] = useState<Team | undefined>(undefined);

  const isMenuOpen = Boolean(anchorEl);

  const router = useRouter();

  const currentUser = useCurrentUser();
  const teamId = useCurrentTeamId();

  useEffect(() => {
    const getNotificationStatus = async () => {
      try {
        const data = await API.getNotificationsStatus();
        const { status } = data;
        setNewCount(status?.new || 0);
      } catch (err: any) {
        //no op
      }
    };

    if (currentUser) {
      getNotificationStatus();
    }

    if (teamId && currentUser) {
      const activeTeam = currentUser.teamPermissions?.find((team) => (
        team.id === teamId
      ));
      setCurrentTeam(activeTeam);
    }
  }, [currentUser, teamId]);

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openCustomerPortal = async () => {
    const response = await API.createStripePortal(router.asPath);
    router.push({
      pathname: (response as any).url,
    });
  };

  const goTo = (href: string) => {
    router.push(href);
    closeMenu();
  };

  const cookies = parseCookies();

  const stopMasquerade = async () => {
    await destroyCookie(null, 'masquerade');
    // force refresh to show we're no longer in masquerade mode
    (window.location as any) = '/';
  };

  const showSearch = () => {
    const { pathname } = router;
    return !currentUser || !(pathname === '/');
  };

  const trackProfileClick = () => {
    trackClick('clickButton', { source: 'app_bar', destination: '/profile' });
    trackClickProfileEvent(API, currentUser?.id || -1);
  };

  const menuId = 'user-menu';

  const getAvatar = (user: User, size: number = 24) => {
    const profilePath = user?.username ? `/${user.username}` : '/profile';
    return (
      (
        <Link
          href={profilePath}
          passHref
          data-testid="profile-link"
          className="text-gray-700 text-center font-semibold"
          onClick={trackProfileClick}
        >

          <Avatar
            alt={user.name}
            src={user.avatarUrl}
            sx={{ width: size, height: size }}
            className="border-orange hover:border-2 m-auto"
          />
          <div className="text-sm hidden md:block">Profile</div>

        </Link>
      )
    );
  };

  const getHamburger = (
    <IconButton
      aria-label="menu"
      aria-controls={menuId}
      aria-haspopup="true"
      onClick={openMenu}
      color="inherit"
    >
      <MenuIcon />
    </IconButton>
  );

  const openManager = () => {
    trackClick('clickButton', { source: 'app_bar', destination: '/manager' });
    goTo('/manager');
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const getUserMenu = (user: User) => {
    const menu = [
      {
        id: 14,
        text: (
          <>
            Notifications
            {newCount > 0 && <div className="ml-2 bg-orange text-white px-1 rounded text-sm font-semibold">{newCount}</div>}
          </>
        ),
        onClick: () => goTo('/notifications'),
      },
      {
        id: 0, text: 'Dashboard', showIf: () => userIsTeamMember(user) && (isMobile || isGondolaApp), onClick: () => goTo('/dashboard'),
      },
      {
        id: 1,
        text: (
          <>
            Visual Match AI
            <span className="cursor-default text-xs font-semibold inline-block py-1 px-2 ml-2 rounded-full bg-teal uppercase">
              NEW
            </span>
          </>
        ),
        onClick: () => goTo('/visualmatch'),
      },
      { id: 2, text: 'Invite a friend', onClick: () => goTo('/invite') },
      {
        id: 3, text: 'Billing', showIf: () => !isGondolaApp && userHasSubscription(user), onClick: openCustomerPortal,
      },
      {
        id: 4, text: 'Content Manager', onClick: () => openManager(),
      },
      {
        id: 5, text: 'Lists', onClick: () => goTo('/lists'),
      },
      {
        id: 6, text: 'Teams', showIf: () => userIsTeamMember(user), onClick: () => goTo('/teams'),
      },
      {
        id: 13, text: 'Brainstorm', showIf: () => userIsTeamMember(user), onClick: () => goTo('/teams/brainstorm'),
      },
      {
        id: 7, text: 'Admin', showIf: () => userIsAdmin(user), onClick: () => goTo('/admin/users'),
      },
      {
        id: 8,
        text: (
          <div className="flex items-center">
            Upgrade to PRO
            <ProIcon height={16} className="ml-2" />
          </div>),
        showIf: () => !userIsProOrAdmin(user) && !isGondolaApp,
        onClick: () => goTo('/plans'),
      },
      { id: 9, text: 'Settings', onClick: () => goTo('/settings') },
      {
        id: 10, text: 'Merch', showIf: () => isGondolaApp, onClick: () => { window.location.href = 'https://merch.gondola.cc/'; },
      },
      { id: 11, text: 'Log out', onClick: () => goTo('/signout') },
      {
        id: 12, text: 'Stop Masquerade', showIf: () => cookies.masquerade && user?.isMasquerading, onClick: stopMasquerade,
      },
      {
        id: 15,
        text: (
          <>
            {currentTeam && (
              <div className="flex">
                <TeamsBadge />
                <div className="ml-2">
                  {currentTeam.name}
                </div>
              </div>
            )}
          </>
        ),
        showIf: () => userIsAdmin(user) && !!currentTeam,
        onClick: () => goTo(`/teams/${currentTeam?.id}/accounts`),
      },
    ];
    return menu
      .filter((m) => m.showIf === undefined || !!m.showIf())
      .map((m) => (
        <MenuItem key={m.id} onClick={m.onClick}>
          {m.text}
        </MenuItem>
      ));
  };

  const getMenuAnchor = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      data-testid={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={closeMenu}
    >
      {currentUser && getUserMenu(currentUser)}
    </Menu>
  );

  const getLinkClassName = (link: string) => {
    const baseClassNames = 'flex flex-col justify-between text-gray-700 font-semibold whitespace-nowrap hover:bg-lightgray hover:bg-opacity-10';
    if (router.pathname.match(link)) {
      return `${baseClassNames} border-b-2 border-orange`;
    }

    return baseClassNames;
  };

  const getSignInMenu = () => {
    if (router.pathname === '/signin' || router.pathname.includes('signup')) return null;

    const href = router.asPath !== '/' ? `/signin?returnTo=${router.asPath}` : '/signin';

    return (
      (
        <Link href={href} passHref className={getLinkClassName('/signin')}>

          {!isMobile && !isGondolaApp && <Login />}
          <div className="text-sm">Sign In</div>

        </Link>
      )
    );
  };

  const getDesktopMenu = (
    <div className="flex items-center gap-4">
      <Link
        href="/explore"
        passHref
        className={getLinkClassName('/explore')}
        data-testid="explore-link"
      >

        <ImageSearchRounded className="mx-auto" />
        <div className="text-sm">Explore</div>

      </Link>
      <Link
        href="/creators"
        passHref
        className={getLinkClassName('/creators')}
        data-testid="creators-link"
      >

        <PersonSearchRounded className="mx-auto" />
        <div className="text-sm">Creators</div>

      </Link>
      {userIsTeamMember(currentUser) && (
        (
          <Link
            href="/dashboard"
            className={getLinkClassName('/dashboard')}
            data-testid="dashboard-link"
          >

            <InsertChartOutlinedIcon className="mx-auto" />
            <div className="text-sm">Dashboard</div>

          </Link>
        )
      )}
      <Link
        href="/jobs"
        className={getLinkClassName('/jobs')}
      >
        <WorkOutline className="mx-auto" />
        <div className="text-sm">Jobs</div>
      </Link>
      <AwaitUserAuth fallback={<Skeleton variant="text" width={45} height={50} />}>
        {currentUser ? (
          <>
            {getAvatar(currentUser)}
            <div className="relative">
              {getHamburger}
              {newCount > 0 && <div className="w-2 h-2 rounded-full bg-orange absolute top-1 right-1" />}
            </div>
          </>
        ) : getSignInMenu()}
      </AwaitUserAuth>
    </div>
  );

  const getMobileMenu = (
    <div className="flex items-center gap-1">
      <Link
        href="/explore"
        passHref
        className={`${getLinkClassName('/explore')} mr-2`}
      >

        <ImageSearchRounded />

      </Link>
      <Link
        href="/creators"
        passHref
        className={`${getLinkClassName('/creators')} mr-2`}
      >

        <PersonSearchRounded />

      </Link>
      <Link
        href="/jobs"
        className={`${getLinkClassName('/jobs')} mr-2`}
      >
        <WorkOutline />
      </Link>
      <AwaitUserAuth fallback={<Skeleton variant="text" width={45} height={40} />}>
        {currentUser ? (
          <>
            {getAvatar(currentUser, 28)}
            <div className="relative">
              {getHamburger}
              {newCount > 0 && <div className="w-2 h-2 rounded-full bg-orange absolute top-1 right-1" />}
            </div>
          </>
        )
          : getSignInMenu()}
      </AwaitUserAuth>
    </div>
  );

  const bgColor = currentUser?.isMasquerading ? 'bg-paleyellow' : 'bg-white';

  return (
    <HideOnScroll>
      <div className={`fixed w-full border-b border-lightgray px-4 md:px-6 py-2 z-50 ${bgColor}`}>
        <div className="grid grid-cols-2 w-full">
          <div className="col-span-1 flex items-center">
            <Link href="/" data-testid="logo" aria-label="gondola">
              <div className="max-w-[120px] md:max-w-[180px]">
                <DesktopLogo className="w-full" />
              </div>
            </Link>
            <div className="lg:hidden">
              {showSearch() && <Search />}
            </div>
            <div className="hidden lg:block pl-20 w-full">
              {showSearch() && (
                <div className="w-full max-w-lg">
                  <GlobalSearch />
                </div>
              )}
            </div>
          </div>
          <div className="col-span-1 flex md:hidden justify-end items-center">{getMobileMenu}</div>
          <div className="col-span-1 hidden md:flex justify-end">{getDesktopMenu}</div>
          {getMenuAnchor}

        </div>
      </div>
    </HideOnScroll>
  );
}
