import {
  isNil, isEmpty, last,
} from 'lodash';
import { Location } from 'lib/types';

const SLUG_DELIMITER = '~';

/**
We want to have the front end urls of the explore page have human readable values
in the query params. But want to avoid making extra api calls and or joins by trying to query
with those human readable values.

So we put the IDs in a slug with a readable value so we can get the  id later
for querying while still adding some readability.
e.g. users=leela~2
 */
export function modelToSlug(modelObj: any, humanReadableField: string) {
  if (isNil(modelObj) || isEmpty(modelObj)) {
    return '';
  }
  const humanReadableValue = modelObj[humanReadableField];
  return `${humanReadableValue}${SLUG_DELIMITER}${modelObj.id}`;
}

export function humanReadableFromSlug(slug: string) {
  if (!slug) {
    return '';
  }

  return slug.split(SLUG_DELIMITER)[0];
}

export function distanceIdFromSlug(slug: string) {
  if (!slug) {
    return '';
  }

  return last(slug.split(SLUG_DELIMITER));
}

export const locationToSlug = (location: Location) => `${location.name}~${location.x},${location.y}`;

export const locationNameToQuery = (name: string) => name.replaceAll(', ', '--').replaceAll(' ', '-');
export const locationNameFromQuery = (name: string) => name.replaceAll('--', ', ').replaceAll('-', ' ');
