import { SVGProps } from 'react';

export const TeamsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 224.7 128.3"
    {...props}
  >
    <g>
      <path fill="#F36F4B" d="M182.8,128.3H41.9C18.7,128.3,0,109.6,0,86.5V41.9C0,18.7,18.7,0,41.9,0h140.9c23.1,0,41.9,18.7,41.9,41.9   v44.6C224.7,109.6,205.9,128.3,182.8,128.3z" />
      <g>
        <path fill="#FFFFFF" d="M105.3,101.6V39.9H81.6V26.7H143v13.2h-23.6v61.7C119.4,101.6,105.3,101.6,105.3,101.6z" />
      </g>
    </g>
  </svg>
);
